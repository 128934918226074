import BergadosImg from '../assets/images/DimitriosIBergados.jpg';

const UoWMMembers = [
  {
    name: "Δημήτριος Ι. Βέργαδος (Επιβλέπων) Αναπληρωτής Καθηγητής, Πρόεδρος του Τμήματος Πληροφορικής",
    img: BergadosImg

  },
  {
    name: "Γιάννης Κέπας",
    socialMedia: "https://www.linkedin.com/in/giannis-kepas/  ",
    email: "mailto:gkepas@ieee.org",
    img: "https://media.licdn.com/dms/image/v2/D4D03AQHjMSfZBX9UmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725538918529?e=1733356800&v=beta&t=vE3xNsNljcrrM-F_Q9sVLW9zZCFV6foxBByqo9yMOKQ"

  },
  {
    name: "Παναγιώτης Παπαντώνης ",
    socialMedia: "https://www.linkedin.com/in/panagiotis-papantonis/",
    email: "mailto:panagiotispapantonis1@gmail.com",
    img: "https://avatars.githubusercontent.com/u/103947010?v=4",
  },
  {
    name: "Βαρβάρα Παναγιωτίδου ",
    socialMedia: "https://www.linkedin.com/in/vana-panagiotidou-aabba430a/",
    email: "mailto:",
    img: "https://avatars.githubusercontent.com/u/132597280?v=4",
  },
  {
    name: "Δημήτρης Παπακωνσταντίνου",
    socialMedia: "https://www.linkedin.com/in/dimitrios-papakonstantinou-44a7672b3/",
    email: "mailto:",
    img: "https://avatars.githubusercontent.com/u/95281510?v=4",
  },
  {
    name: "Σωτήρης Ζαχαρόπουλος",
    socialMedia: "",
    email: "mailto:",
    img: "https://avatars.githubusercontent.com/u/58138432?v=4",
  },
];

export default UoWMMembers;
