export default {
    StudentCare: "Σαλασίδου Δέσποινα, Τηλέφωνο: 2467440003, E-mail: dsalasidou@uowm.gr, merimna-kas@uowm.gr",
    //constats.js --> Φοιτητική Μέριμνα
  FoititikhMerimna: "Φοιτητική Μέριμνα",
  Myfeo: "Μονάδα Συμβουλευτικής και Ψυχολογικής Υποστήριξης ΜΥΦΕΟ",
  OlistikhMerimna: "Ολιστική Μέριμνα",
  MedicalCare: "Ιατροφαρμακευτική Περίθαλψη",
  Sitish: "Σίτιση",
  Stegash: "Στέγαση",
  FoithtikoEpidoma: "Φοιτητικό Στεγαστικό Επίδομα",
  EuroCardAsfalish: "Ευρωπαική κάρτα ασφάλισης",
  };
  